.ic-button {
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

.ic-circle {
  width: 10px;
  min-height: 10px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
