//Colores Generales
$ica-backgroung: #000000;
$ica-primary: #70be01;
$ica-secondary: #5e5e5e;
$ica-textsidebar: #ffffff;
$ica-componettotales: #ececec;
$ica-topost: #01beb3;
$ica-third: #000000;
$ica-cars-sold: rgb(68 64 60);
$ica-cars-offers: rgb(74 222 128);
