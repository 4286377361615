@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import './custom-theme.scss';
@import 'variables';
@import 'button';
@import './primeng.scss';

html,
body {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.ic-to-post {
  background-color: $ica-topost;
}

.ic-published {
  background-color: $ica-primary;
}

.ic-sold {
  background-color: $ica-backgroung;
}

/* Se coloca el color de los tabs de forma general */

.ic-tabs {
  padding-bottom: 50px;
}

.mat-badge-content {
  background: $ica-primary !important;
}

/* Estilos compartidos generados con Tailwind CSS */
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

@layer base {
  * {
    font-family: 'Poppins';
    font-style: normal;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-32 {
  font-size: 32px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.ic-icon.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

.material-icons.ic-icon {
  color: #5bc504;
  background-color: transparent;
}

.material-icons.ic-icon-red {
  color: #dc2626;
  background-color: transparent;
}

@layer components {
  /**Botones**/
  .ic-btn {
    @apply inline-flex max-h-9 items-center justify-center rounded-3xl border-2 border-transparent px-4 py-1
            text-sm font-normal text-ica-textsidebar shadow-sm focus:outline-none
            enabled:hover:bg-white enabled:hover:text-ica-backgroung
            disabled:cursor-none disabled:bg-zinc-500 disabled:opacity-60 md:text-base;
  }
  .ic-btn-primary {
    @apply ic-btn bg-ica-primary enabled:hover:border-ica-primary;
  }
  .ic-btn-secondary {
    @apply ic-btn bg-ica-secondary enabled:hover:border-ica-secondary;
  }
  .ic-btn-third {
    @apply ic-btn bg-ica-third enabled:hover:border-ica-third;
  }

  .ic-btn-date {
    @apply flex cursor-pointer items-center text-xl font-semibold text-ica-secondary;
  }

  .ic-links {
    @apply ml-1 flex cursor-pointer items-center font-extra-bold text-ica-primary hover:text-green-500;
  }

  /** Textos y etiquetas ***/
  .li-menu {
    @apply inline-flex items-center text-lg font-medium text-ica-textsidebar hover:text-ica-primary;
  }

  .li-menu-module {
    @apply li-menu font-medium;
  }

  .ic-label-normal {
    @apply font-normal md:text-base;
  }

  .ic-label-primary {
    @apply text-sm font-semibold leading-7 text-ica-third md:text-base;
  }

  .ic-label-primary-xl {
    @apply text-lg font-extrabold leading-6 text-ica-third md:text-xl;
  }

  .ic-label-secondary {
    @apply text-sm font-normal leading-6 text-ica-third md:text-base;
  }

  /** elementos de busqueda para la tabla **/
  .ic-input-search {
    @apply block w-full rounded-l-3xl border
    border-gray-300 py-[0.3rem] pl-10 text-base text-ica-backgroung focus:border-ica-primary focus:ring-0 md:py-[0.4rem];
  }

  .ic-button-search {
    @apply inline-flex max-w-[88px] items-center rounded-r-3xl border border-ica-primary bg-ica-primary px-2
    py-[0.3rem] text-base font-medium text-white md:py-[0.4rem];
  }

  .ic-action-delete {
    @apply ic-btn mx-2 bg-red-600 p-1 enabled:hover:border-red-600;
  }

  .ic-action-edit {
    @apply ic-btn mx-2 bg-ica-primary p-1 enabled:hover:border-ica-primary;
  }

  .ic-action-third {
    @apply ic-btn mx-2 bg-ica-third p-1 enabled:hover:border-ica-third;
  }

  .ic-action-down {
    @apply ic-btn mx-2 bg-red-500 p-1 enabled:hover:border-red-500;
  }

  .ic-action-custom {
    @apply ic-btn mx-2 p-1 enabled:hover:border-ica-third;
  }

  /**Etiquetas con un tamaño en especifico**/
  .ic-label-1 {
    @apply text-2xl font-semibold;
  }

  .ic-label-2 {
    @apply text-3xl font-black;
  }

  .ic-label-encabezado {
    @apply text-3xl font-semibold leading-8 text-ica-primary;
  }

  .ic-label-subtitle {
    @apply text-xl font-semibold leading-8 text-ica-primary;
  }

  .ic-input-search-table {
    @apply block w-full rounded-l-3xl border border-gray-300 py-[0.3rem] pl-10 text-base text-ica-backgroung focus:border-ica-primary focus:ring-0 md:py-[0.4rem];
  }
}

input,
textarea,
select {
  --tw-ring-shadow: 0 0 #000 !important;
}

/* Styles for the ink bar */
.mat-ink-bar {
  background-color: $ica-primary !important;
}

/* Styles para la barra de desplazamiento */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(112, 190, 1, 0.5);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, 1);
}

/* Agrega un efecto hover a la barra de desplazamiento */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(112, 190, 1, 0.8);
}
