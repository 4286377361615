.p-scrollpanel {
  &.custom_panel {
    .p-scrollpanel-wrapper {
      border-right: 8px solid var(--layer-1);
    }

    .p-scrollpanel-bar {
      background-color: $ica-primary;
      opacity: 1;
      transition: background-color 0.2s;
      bottom: calc(0%);
      &:hover {
        background-color: $ica-primary;
      }
    }
  }
}
